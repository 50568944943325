<template>
  <div class="m-x-25p mb:m-25">
    <div class="mt-20 mb-125 mb:mb-30 flex-start-center mb:flex-col-reverse mb:items-center mb:justify-center gap-20">
      <div class="w-50p mb:w-full mt-140 mb:mt-0 mb:text-center flex-center-col">
        <h1 class="font-libItalic font-64 mb:font-32">Hello!</h1>
        <h1 class="font-libItalic font-64 mb:font-32">I'm Keyla.</h1>
        <a href="./Resume.pdf" target="_blank" class="box-shadow inline-block no-underline bg-gray color-white text-uppercase bg-blue mt-20 mb:mt-15 p-y-20 mb:p-y-15 mb:p-x-50 font-robBold font-20 mb:font-14 radius-10 p-x-75">View resume</a>
      </div>
      <div class="w-50p mb:w-full flex-center">
        <img src="@/assets/images/about/me.png" class="w-full">
      </div>
    </div>
    <div class="flex flex-col gap-20 mb-125 mb:mb-30">
      <p>I am a recent graduate from the six month long Google UX/UI Design certification bootcamp program. In my course, I learned the importance of user research and how to properly conduct preliminary research, competitive analysis, and user interviews.</p>
      <p>Created user journey maps, user personas, user flow charts, information architecture charts, and storyboards. Gained working knowledge on sketching, creating Lo-Fi wireframes, converting sketching into Hi-Fi prototypes, conducted usability studies both moderated and unmoderated, reiterating on my designs, and measuring product success.</p>
      <p>By the end of the six month bootcamp, I had created a mobile application, a responsive website, and a combination of the two in Figma and Adobe XD.</p>
      <p>In my free time, I try to better myself by learning new skills. I’ve completed many programming courses from Codecademy and has experience in HTML, CSS, and C#. I am currently learning Flutter to develop mobile applications.</p>
      <p>Outside of the technical world, I am a self-taught musician, guitarist, ukuleleist, recording artist, self-published novelist, and an illustrator.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
